import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealAdolescentBrain'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const TextReveal = makeShortcode("TextReveal");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "why-the-ups-and-downs"
    }}>{`Why the ups and downs?`}</h1>
    <p>{`It is normal for teenagers to experience more extreme ‘ups and downs’ in their emotions. As a parent, you might find it hard to tell why your teen’s mood and behaviours change.`}</p>
    <p>{`Many of the changes in adolescents’ emotions, thinking, and behaviour are due to rapid brain changes that are a healthy and necessary part of their development into adults. It might be helpful for you to keep this in mind if you find yourself getting confused or frustrated about how your teen is acting.`}</p>
    <p>{`You could also chat with your teenager about the changes happening in their brain and how this might affect them.`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <Img src="/images/m1/24.svg" alt="cool down and sort" mdxType="Img" />
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealAdolescentBrain" header="The adolescent brain (click to reveal)" mdxType="TextReveal">
      <p>Below are some of the main changes during adolescence:</p>
      <ul>
        <li>
          Connections between parts of the brain change: Unused connections are ‘trimmed’ away, and other connections
          become stronger.
        </li>
        <li>
          Changes begin towards the back of the brain and move toward the front: these changes continue into the
          mid-to-late 20s.
        </li>
        <li>
          The front part of the brain (called the <b>prefrontal cortex</b>) is involved in decision-making, planning,
          rational thinking, problem solving, and impulse control. Because this part of the brain is still developing
          during adolescence, teens are still learning how to master these ‘executive skills’.
        </li>
        <li>
          The ‘emotional centre’ of the brain, called the <b>amygdala</b>, is more developed than the front part of the
          brain. This can make teens act impulsively and based on how they ‘feel’.
        </li>
        <li>
          Teenage brains are also wired to seek reward or pleasure. At the same time, their immature frontal lobes make
          it hard for teens to foresee the consequences of their behaviour.
        </li>
      </ul>
      <p>
        Because of these changes, teens are less able to regulate their emotions and hence, are more likely to
        experience stronger emotions.
      </p>
    </TextReveal>
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      